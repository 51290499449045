import {
  FoundAddress,
  FoundBillingAddress,
  isFoundBillingAddress,
} from '@/types/moveHome';

export type AddressOption = {
  value: string | number;
  label: string;
  propertyId?: string;
};
export type AddressOptions = Array<AddressOption>;

export const getAddressList = (
  addresses: FoundAddress[] | FoundBillingAddress[] | undefined
): AddressOptions => {
  if (!addresses) {
    return [];
  }

  const addressDataValues: AddressOptions = addresses
    .map((address: FoundAddress | FoundBillingAddress) => {
      if (!address.node) return;

      if (isFoundBillingAddress(address)) {
        return {
          value: address.node,
          label: address.node,
        };
      } else {
        return {
          value: address.node.address,
          label: address.node.address,
          propertyId: address.node.propertyId ?? undefined,
          metered: address.node.metered ?? undefined,
        };
      }
    })
    .filter((address) => !!address);

  return addressDataValues.sort((a, b) => {
    const numA = parseInt(a.value.toString(), 10);
    const numB = parseInt(b.value.toString(), 10);

    const addressA = isNaN(numA) ? 0 : numA;
    const addressB = isNaN(numB) ? 0 : numB;

    return addressA - addressB;
  });
};
