export enum FeatureNames {
  Usage = 'USAGE',
  CommsPreferences = 'COMMS_PREFERENCES',
  Bills = 'BILLS',
  ResetPassword = 'RESET_PASSWORD',
  OnlinePayment = 'ONLINE_PAYMENT',
  DirectDebit = 'DIRECT_DEBIT',
  DirectDebitVersion2 = 'DIRECT_DEBIT_V2',
  AccountEnrichment = 'ACCOUNT_ENRICHMENT',
  PriorityServicesRegister = 'PRIORITY_SERVICES_REGISTER',
  PSRRenewal = 'PSR_RENEWAL',
  HomeMove = 'HOME_MOVES',
  RegisterForOnlineAccount = 'REGISTER_FOR_ONLINE_ACCOUNT',
  ForgottenEmail = 'FORGOTTEN_EMAIL',
  CreateNewAccount = 'CREATE_NEW_ACCOUNT',
  AdobeAnalytics = 'ADOBE_ANALYTICS',
  GoogleAnalytics = 'GOOGLE_ANALYTICS',
}
