import { PropsWithChildren, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Loader } from '@krakentech/coral';

import { AnimatedAlert, PageError, StepperLayout } from '@/components';
import { ANIMATED_ALERT_STATUS } from '@/consts/animatedAlertStatus';
import { MOVE_HOME, NO_FINAL_READ } from '@/consts/moveHome';
import { useHasNotMovedOut } from '@/hooks/moveHome/useHasNotMovedOut';
import { useWillNotMoveOutInFuture } from '@/hooks/moveHome/useWillNotMoveOutInFuture';
import { useHasActiveWaterMeter } from '@/hooks/usage/useHasActiveWaterMeter';
import useDunningDialog from '@/hooks/utils/useDunningDialog';
import CustomHead from '@/pages/_head.page';
import { useHomeMoveContext } from '@/pages/move-home/context';
import { closeAnimatedAlert } from '@/utils/alerts';
import { INTERNAL_PATHS } from '@/utils/urls';

const HomeMoveLayout = ({ children }: PropsWithChildren) => {
  const { push, asPath } = useRouter();

  const { data: hasNotMovedOut, isSuccess: hasNotMovedOutSuccess } =
    useHasNotMovedOut();

  const {
    data: willNotMoveOutInTheFuture,
    isSuccess: willNotMoveOutInTheFutureSuccess,
  } = useWillNotMoveOutInFuture();

  const {
    data: hasActiveWaterMeter,
    isLoading,
    isError,
  } = useHasActiveWaterMeter();

  const { redirectWithActiveDunning } = useDunningDialog();

  const {
    setIsMetered,
    moveOutDate,
    setPagesInJourney,
    pagesInJourney,
    finalReading,
  } = useHomeMoveContext();

  const [alertStatus, setAlertStatus] = useState(ANIMATED_ALERT_STATUS.CLOSED);

  useEffect(() => {
    redirectWithActiveDunning();
  }, [redirectWithActiveDunning]);

  useEffect(() => {
    if (!hasNotMovedOutSuccess || !willNotMoveOutInTheFutureSuccess) {
      return;
    }

    // Redirect to home page if user has or will move out and manually goes to url
    if (!hasNotMovedOut || !willNotMoveOutInTheFuture) {
      push(INTERNAL_PATHS.HOME.path);
    }
  }, [
    hasNotMovedOutSuccess,
    willNotMoveOutInTheFutureSuccess,
    hasNotMovedOut,
    willNotMoveOutInTheFuture,
    push,
  ]);

  useEffect(() => {
    if (
      finalReading &&
      finalReading !== NO_FINAL_READ &&
      asPath.includes('new-address')
    ) {
      setAlertStatus(ANIMATED_ALERT_STATUS.OPEN);
    }
  }, [finalReading, asPath, setAlertStatus]);

  const homeMovePaths = INTERNAL_PATHS.MOVE_HOME.filter(
    (path) => path.name !== MOVE_HOME.SUCCESS
  );

  const getCurrentStep = () => {
    const activeStepIndex = pagesInJourney.findIndex(
      (page) => asPath === page.path
    );

    if (activeStepIndex !== -1) {
      return activeStepIndex;
    }

    return 0;
  };

  const currentStep = getCurrentStep();

  useEffect(() => {
    if (typeof hasActiveWaterMeter !== 'undefined') {
      setIsMetered(hasActiveWaterMeter);
    }
  }, [hasActiveWaterMeter, setIsMetered]);

  // Set the pagesInJourney variable dynamically based on whether the user is metered or not, as we want to skip the final reading page if they are unmeasured
  useEffect(() => {
    if (typeof hasActiveWaterMeter !== 'undefined') {
      if (hasActiveWaterMeter) {
        setPagesInJourney(homeMovePaths);
      } else {
        const journeyWithoutFinalReadingPage = homeMovePaths.filter(
          (path) => path.name !== MOVE_HOME.FINAL_READ
        );
        setPagesInJourney(journeyWithoutFinalReadingPage);
      }
    }
    // NOTE: Having to ignore the below because adding homeMovePaths will cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasActiveWaterMeter]);

  // Redirect user to beginning of the journey if they try to access a later page without completing the previous ones
  useEffect(() => {
    if (currentStep > 0 && !moveOutDate && pagesInJourney.length) {
      push(pagesInJourney[0].path);
    }
  }, [moveOutDate, currentStep, pagesInJourney, push]);

  if (isLoading) {
    // TODO: build actual loading state so we have better performance metrics
    return <Loader type="linear" variant="indeterminate" />;
  }

  if (isError) {
    return <PageError />;
  }

  if (!pagesInJourney.length) {
    return null;
  }

  const getStepConfigs = () => {
    return pagesInJourney.map((page) => ({
      title: page.name,
      'aria-label': `Step - ${page.name}`,
    }));
  };

  return (
    <>
      <CustomHead title={INTERNAL_PATHS.MOVE_HOME[currentStep].title} />
      <StepperLayout
        heading="Moving home"
        totalSteps={pagesInJourney.length}
        activeStep={currentStep + 1}
        stepConfigs={getStepConfigs()}
        onStepClicked={(step) => {
          push(pagesInJourney[step - 1].path);
        }}
      >
        <AnimatedAlert
          alertStatus={alertStatus}
          alertMessage="Your reading has been submitted"
          handleAlertClose={() => closeAnimatedAlert(setAlertStatus)}
        />
        {children}
      </StepperLayout>
    </>
  );
};

export default HomeMoveLayout;
