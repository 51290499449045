import { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import {
  FoundAddress,
  FoundBillingAddress,
  UseAddressLookupParams,
} from '@/types/moveHome';

/**
 * Hook to create a more sensible API around data fetching of addresses.
 * Resets the data fetched when a new fetch is request, to simplify the logic in
 * the consuming components.
 */
export function useFetchAddressData(
  query: ({
    postcode,
  }: UseAddressLookupParams) => UseQueryResult<
    FoundAddress[] | FoundBillingAddress[]
  >,
  postcodeValue: string
) {
  const [hasFetched, setHasFetched] = useState(false);
  const [fetchedData, setFetchedData] = useState<
    FoundAddress[] | FoundBillingAddress[] | undefined
  >(undefined);
  const {
    data: addressData,
    refetch: refetchAddressData,
    isFetching: fetchingAddressData,
    isError,
  } = query({ postcode: postcodeValue });

  useEffect(() => {
    if (!fetchingAddressData) setFetchedData(addressData);
  }, [addressData, fetchingAddressData]);

  const fetchFailed = hasFetched && isError;

  const fetchData = async () => {
    setFetchedData(undefined);
    setHasFetched(true);
    await refetchAddressData();
  };

  return {
    fetchingAddressData,
    fetchFailed,
    fetchData,
    fetchedData,
  };
}
