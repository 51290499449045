import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { PagesInJourney } from '@/components/layouts/HomeMoveLayout/index.types';
import { NewAddressType } from '@/types/moveHome';

import {
  HomeMoveContextReturnValue,
  MoveOutContextInitials,
} from './context.types';

export const HomeMoveContext = createContext({} as HomeMoveContextReturnValue);

export const HomeMoveOutContextProvider = (
  props: PropsWithChildren & MoveOutContextInitials
) => {
  const {
    initialPagesInJourney = [],
    initialIsMetered = false,
    initialMoveOutDate = undefined,
    initialMoveInDate = undefined,
    initialFinalReading = undefined,
    initialNewBillingAddress = undefined,
    initialHasNewAddress = undefined,
    initialIsResponsibleForBill = undefined,
    initialPostcode = undefined,
  } = props;
  const [pagesInJourney, setPagesInJourney] = useState<PagesInJourney>(
    initialPagesInJourney
  );
  const [isMetered, setIsMetered] = useState<boolean>(initialIsMetered);
  const [moveOutDate, setMoveOutDate] = useState<Date | undefined>(
    initialMoveOutDate
  );
  const [moveInDate, setMoveInDate] = useState<Date | undefined>(
    initialMoveInDate
  );
  const [finalReading, setFinalReading] = useState<string | undefined>(
    initialFinalReading
  );
  const [newBillingAddress, setNewBillingAddress] = useState<
    NewAddressType | undefined
  >(initialNewBillingAddress);
  const [hasNewAddress, setHasNewAddress] = useState<string | undefined>(
    initialHasNewAddress
  );
  const [isResponsibleForBill, setIsResponsibleForBill] = useState<
    string | undefined
  >(initialIsResponsibleForBill);

  /* Setting postcode from the frontend input because the BE 
    doesn't seem to return one */
  const [postcode, setPostcode] = useState<string | undefined>(initialPostcode);

  return (
    <HomeMoveContext.Provider
      value={{
        isMetered,
        setIsMetered,
        moveOutDate,
        setMoveOutDate,
        moveInDate,
        setMoveInDate,
        finalReading,
        setFinalReading,
        pagesInJourney,
        setPagesInJourney,
        setNewBillingAddress,
        newBillingAddress,
        setPostcode,
        postcode,
        hasNewAddress,
        setHasNewAddress,
        isResponsibleForBill,
        setIsResponsibleForBill,
      }}
    >
      {props.children}
    </HomeMoveContext.Provider>
  );
};

export const useHomeMoveContext = () => {
  const context = useContext(HomeMoveContext);
  if (context === undefined) {
    throw new Error(
      'useHomeMoveContext must be used within a HomeMoveContextProvider'
    );
  }
  return context;
};
