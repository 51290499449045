import { useRouter } from 'next/router';
import { Stack, Card, Typography } from '@krakentech/coral';

import ReviewDetail from '@/components/ReviewDetail';

type UserRegisterDetailsSummaryProps = {
  userDetails: { title: string; detail: string; changeDetailPath: string }[];
};

const UserRegisterDetailsSummary = ({
  userDetails,
}: UserRegisterDetailsSummaryProps) => {
  const { push } = useRouter();

  return (
    <Stack direction="vertical" gap="smMd">
      <Card>
        <Stack direction="vertical" gap="smMd">
          <Typography variant="h2">Your details</Typography>
          {userDetails.map((item) => (
            <ReviewDetail
              key={item.title}
              title={item.title}
              details={item.detail}
              handleOnClick={() => push(item.changeDetailPath)}
            />
          ))}
        </Stack>
      </Card>
    </Stack>
  );
};

export default UserRegisterDetailsSummary;
