export type NewAddressType = {
  value: string;
  label: string;
  propertyId?: string;
  metered?: string;
};

export type NewAddressSubmitValues = {
  address: NewAddressType;
  moveInDate: Date | undefined;
  postcode: string;
};

export type UseAddressLookupParams = {
  postcode: string;
};

export type FoundAddress = {
  node: {
    address: string;
    propertyId?: string | null;
    metered?: boolean | null;
  };
};

export type FoundBillingAddress = {
  node: string;
};

export const isFoundBillingAddress = (
  address: FoundAddress | FoundBillingAddress
): address is FoundBillingAddress => {
  return typeof address.node === 'string';
};
