import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { datadogRum } from '@datadog/browser-rum';

import { FeatureNames } from '@/types/features';

import { QueryParams, EnvFlagsType } from './index.types';

// a map of flag names to their values
const FLAG_NAME_TO_ENV_VALUE: EnvFlagsType = {
  [FeatureNames.Usage]:
    (process.env.NEXT_PUBLIC_FF_USAGE as string) || undefined,
  [FeatureNames.CommsPreferences]:
    (process.env.NEXT_PUBLIC_FF_COMMS_PREFERENCES as string) || undefined,
  [FeatureNames.Bills]:
    (process.env.NEXT_PUBLIC_FF_BILLS as string) || undefined,
  [FeatureNames.ResetPassword]:
    (process.env.NEXT_PUBLIC_FF_RESET_PASSWORD as string) || undefined,
  [FeatureNames.OnlinePayment]:
    (process.env.NEXT_PUBLIC_FF_ONLINE_PAYMENT as string) || undefined,
  [FeatureNames.DirectDebit]:
    (process.env.NEXT_PUBLIC_FF_DIRECT_DEBIT as string) || undefined,
  [FeatureNames.DirectDebitVersion2]:
    (process.env.NEXT_PUBLIC_FF_DIRECT_DEBIT_V2 as string) || undefined,
  [FeatureNames.AccountEnrichment]:
    (process.env.NEXT_PUBLIC_FF_ACCOUNT_ENRICHMENT as string) || undefined,
  [FeatureNames.PriorityServicesRegister]:
    (process.env.NEXT_PUBLIC_FF_PSR as string) || undefined,
  [FeatureNames.PSRRenewal]:
    (process.env.NEXT_PUBLIC_FF_PSR_RENEWAL as string) || undefined,
  [FeatureNames.HomeMove]:
    (process.env.NEXT_PUBLIC_FF_HOME_MOVES as string) || undefined,
  [FeatureNames.RegisterForOnlineAccount]:
    (process.env.NEXT_PUBLIC_FF_REGISTER_FOR_ONLINE_ACCOUNT as string) ||
    undefined,
  [FeatureNames.ForgottenEmail]:
    (process.env.NEXT_PUBLIC_FF_FORGOTTEN_EMAIL as string) || undefined,
  [FeatureNames.CreateNewAccount]:
    (process.env.NEXT_PUBLIC_FF_CREATE_NEW_ACCOUNT as string) || undefined,
  [FeatureNames.AdobeAnalytics]:
    (process.env.NEXT_PUBLIC_FF_ADOBE_ANALYTICS as string) || undefined,
  [FeatureNames.GoogleAnalytics]:
    (process.env.NEXT_PUBLIC_FF_GOOGLE_ANALYTICS as string) || undefined,
};

export const useFeatureFlag = (flagName: string): boolean => {
  const [isFeatureShown, setIsFeatureShown] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const queryParams = (router.query as QueryParams) || {};
    const { FF_ALL } = queryParams;
    if (router.isReady) {
      const featureFlagValue =
        FF_ALL === '1' || !isFeatureHidden({ flagName, queryParams });
      setIsFeatureShown(featureFlagValue);
      datadogRum.addFeatureFlagEvaluation(flagName, featureFlagValue);
    }
  }, [flagName, router.isReady, router.query]);

  return isFeatureShown;
};

export const getExplicitFeatureFlag = ({
  flagName,
  queryParams,
}: {
  flagName: string;
  queryParams: QueryParams;
}): string => {
  const explicitFlagName = flagName.replace('NEXT_PUBLIC_', '');
  return queryParams[explicitFlagName] || queryParams[flagName];
};

export const isFeatureHidden = ({
  flagName,
  queryParams = {},
  environmentFlags = FLAG_NAME_TO_ENV_VALUE,
}: {
  flagName: string;
  queryParams?: QueryParams;
  environmentFlags?: EnvFlagsType;
}): boolean => {
  const explicitFeatureFlag = getExplicitFeatureFlag({
    flagName,
    queryParams,
  });

  // User manually set feature flag
  if (explicitFeatureFlag === '1') {
    return false;
  }

  // User manually disabled feature flag
  if (explicitFeatureFlag === '0') {
    return true;
  }

  if (typeof window !== 'undefined') {
    /* checked we're not on the server */
    if (window.localStorage.getItem(flagName) === '1') {
      return false;
    }
  }

  if (flagName in environmentFlags) {
    // if we haven't set it in the environment, or we've set it to 0, hide it
    const environmentValue = environmentFlags[flagName as keyof EnvFlagsType];
    return environmentValue === '0' || environmentValue === undefined;
  }
  // if there has been nothing set for the flag, we should hide it
  return true;
};
